<script lang='ts'>
  import IconEnvelope from "@/components/icons/phosphor/IconEnvelope.svelte";
  import IconFacebookLogo from "@/components/icons/phosphor/IconFacebookLogo.svelte";
  import IconInstagramLogo from "@/components/icons/phosphor/IconInstagramLogo.svelte";
  import IconLinkedinLogo from "@/components/icons/phosphor/IconLinkedinLogo.svelte";
  import IconRedditLogo from "@/components/icons/phosphor/IconRedditLogo.svelte";
  import IconTelegramLogo from "@/components/icons/phosphor/IconTelegramLogo.svelte";
  import IconTwitterLogo from "@/components/icons/phosphor/IconTwitterLogo.svelte";
  import IconWhatsappLogo from "@/components/icons/phosphor/IconWhatsappLogo.svelte";

  export let type = '', url = '', title = '', desc = ''

  let href: string
  let svg: string

  switch (type) {
    case 'email':
      href = encodeURI(`mailto:?subject=${title}&body=${desc}`)
      svg = 'Envelope'
      break
    case 'telegram':
      href = encodeURI(`https://telegram.me/share/url?url=${url}&text=${title}`)
      svg = 'TelegramLogo'
      break
    case 'facebook':
      href = encodeURI(`https://facebook.com/sharer/sharer.php?u=${url}&quote=${title}`)
      svg = 'FacebookLogo'
      break
    case 'line':
      href = encodeURI(`https://social-plugins.line.me/lineit/share?url=${url}`)
      svg = `<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='M12 0C5.37 0 0 5.37 0 12c0 5.99 4.38 10.96 10.12 11.9V15.5h-2.75v-3.5h2.75V9.73c0-2.72 1.63-4.22 4.1-4.22 1.18 0 2.2.09 2.5.13v2.86h-1.72c-1.34 0-1.6.64-1.6 1.58v2.08h3.22l-.42 3.5h-2.8V24C19.62 22.96 24 17.99 24 12z' fill='#777' /></svg>`
      break
    case 'linkedin':
      href = encodeURI(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${desc}`)
      svg = 'LinkedinLogo'
      break
    case 'reddit':
      href = encodeURI(`https://reddit.com/submit?url=${url}&title=${title}`)
      svg = 'RedditLogo'
      break
    case 'tumblr':
      href = encodeURI(`https://www.tumblr.com/widgets/share/tool?canonicalUrl=${url}&title=${title}&caption=${desc}`)
      svg = ` <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'> <path d='M13.5.5v5h5v4h-5V15c0 5 3.5 4.4 6 2.8v4.4c-6.7 3.2-12 0-12-4.2V9.5h-3V6.7c1-.3 2.2-.7 3-1.3.5-.5 1-1.2 1.4-2 .3-.7.6-1.7.7-3h3.8z' fill='#35465c' /> </svg>`
      break
    case 'twitter':
      href = encodeURI(`https://twitter.com/intent/tweet?text=${title}&url=${url}`)
      svg = 'TwitterLogo'
      break
    case 'vk':
      href = encodeURI(`https://vk.com/share.php?url=${url}`)
      svg = `<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'> <path d='M21.547 7h-3.29a.743.743 0 0 0-.655.392s-1.312 2.416-1.734 3.23C14.734 12.813 14 12.126 14 11.11V7.603A1.104 1.104 0 0 0 12.896 6.5h-2.474a1.982 1.982 0 0 0-1.75.813s1.255-.204 1.255 1.49c0 .42.022 1.626.04 2.64a.73.73 0 0 1-1.272.503 21.54 21.54 0 0 1-2.498-4.543.693.693 0 0 0-.63-.403h-2.99a.508.508 0 0 0-.48.685C3.005 10.175 6.918 18 11.38 18h1.878a.742.742 0 0 0 .742-.742v-1.135a.73.73 0 0 1 1.23-.53l2.247 2.112a1.09 1.09 0 0 0 .746.295h2.953c1.424 0 1.424-.988.647-1.753-.546-.538-2.518-2.617-2.518-2.617a1.02 1.02 0 0 1-.078-1.323c.637-.84 1.68-2.212 2.122-2.8.603-.804 1.697-2.507.197-2.507z' fill='507299' /> </svg>`
      break
    case 'whatsapp':
      href = encodeURI(`whatsapp://send?text=${title + ' ' + url}`)
      svg = 'WhatsappLogo'
      break
    case 'xing':
      href = encodeURI(`https://www.xing.com/app/user?op=share;url=${url};title=${title}`)
      svg = `<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='M10.2 9.7l-3-5.4C7.2 4 7 4 6.8 4h-5c-.3 0-.4 0-.5.2v.5L4 10 .4 16v.5c0 .2.2.3.4.3h5c.3 0 .4 0 .5-.2l4-6.6v-.5zM24 .2l-.5-.2H18s-.2 0-.3.3l-8 14v.4l5.2 9c0 .2 0 .3.3.3h5.4s.3 0 .4-.2c.2-.2.2-.4 0-.5l-5-8.8L24 .7V.2z' fill='#1a7576' /></svg>`
      break
    default:
      href = ''
      svg = ''
  }
</script>

<a class='share-link' aria-label={`Share on ${type}`} {href} rel='noopener noreferrer' target='_blank'>
  <div class='icon' aria-hidden='true'>
    {#if type === 'tumblr' || type === 'vk'}
      {@html svg}
    {:else if svg === 'WhatsappLogo'}
      <IconWhatsappLogo />
    {:else if svg === 'TwitterLogo'}
      <IconTwitterLogo />
    {:else if svg === 'RedditLogo'}
      <IconRedditLogo />
      {:else if svg === 'LinkedinLogo'}
      <IconLinkedinLogo />
      {:else if svg === 'FacebookLogo'}
      <IconFacebookLogo />
      {:else if svg === 'TelegramLogo'}
      <IconTelegramLogo />
      {:else if svg === 'InstagramLogo'}
      <IconInstagramLogo />
      {:else if svg === 'Envelope'}
      <IconEnvelope />
    {/if}
  </div>
</a>

<style lang='scss'>
  .share-link {
    display: inline-block;
    text-decoration: none;
    color: var(--inverted-text-color);
    transition: 0.025s ease-out;

    &:hover {
      opacity: 0.8;
    }

    > .icon {
      display: inline-block;
      color: var(--Base-Black);

      > :global(svg) {
        width: 2.4rem;
        height: 2.4rem;
        margin: 0;
        vertical-align: middle;
      }
    }
  }
</style>
